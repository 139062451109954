<template>
  <div class="progress-ring">
    <svg :height="radius * 2" :width="radius * 2">
      <circle></circle>
      <circle
        stroke="white"
        fill="transparent"
        stroke-linecap="round"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset: strokeDashoffset }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      ></circle>
    </svg>

    <div class="content content-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    radius: {
      type: Number,
      default: 100
    },
    progress: {
      type: Number,
      default: 0
    },
    stroke: {
      type: Number,
      default: 5
    }
  },
  data() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference
    };
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    }
  }
};
</script>
